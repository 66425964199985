import { Injectable } from '@angular/core';
import { flatten } from '@interacta-shared/util';
import { from, lastValueFrom, map, merge, mergeMap, toArray } from 'rxjs';

export type GetReferencedPostsIdsFunction = () => Promise<number[]>;

@Injectable({
    providedIn: 'root',
})
export class AllPostsExternalReferencesService {
    private readonly referencesFunctions: Record<
        string,
        GetReferencedPostsIdsFunction
    > = {};

    addReferencesFunction(
        key: string,
        fn: GetReferencedPostsIdsFunction,
    ): void {
        this.referencesFunctions[key] = fn;
    }

    async getAllExternalReferencedPostsIds(): Promise<number[]> {
        return lastValueFrom(
            merge(
                Object.values(this.referencesFunctions).map((fn) => from(fn())),
            ).pipe(
                mergeMap((a) => a),
                toArray(),
                map(flatten),
            ),
        );
    }
}
